import axios from 'axios';
import { Player } from '../models/player';
import flatten from 'flat';

export default {
    namespaced: true,

    state: {
        player: new Player(),
        loadingPlayer: false,
        loadingError: null,
        savingPlayer: false,
        savingError: null,
        playerHasPlayerZoneAccess: false,
        loadingPzPlayerAccesses: false,
        loadingErrorPzPlayerAccesses: null,
    },

    getters: {
        player: (state) => state.player,
        loadingPlayer: (state) => state.loadingPlayer,
        loadingError: (state) => state.loadingError,
        savingPlayer: (state) => state.savingPlayer,
        savingError: (state) => state.savingError,
        playerHasPlayerZoneAccess: (state) => state.playerHasPlayerZoneAccess,
        loadingPzPlayerAccesses: (state) => state.loadingPzPlayerAccesses,
        loadingErrorPzPlayerAccesses: (state) => state.loadingErrorPzPlayerAccesses,
    },

    mutations: {
        setPlayer(state, data) {
            state.player = data;
        },

        startLoadingPlayer(state) {
            state.loadingPlayer = true;
        },

        stopLoadingPlayer(state) {
            state.loadingPlayer = false;
        },

        setLoadingError(state, error) {
            state.loadingError = error;
        },

        clearLoadingError(state) {
            state.loadingError = null;
        },

        startSavingPlayer(state) {
            state.savingPlayer = true;
        },

        stopSavingPlayer(state) {
            state.savingPlayer = false;
        },

        setSavingError(state, error) {
            state.savingError = error;
        },

        clearSavingError(state) {
            state.savingError = null;
        },
        setPlayerHasPlayerZoneAccess(state, data) {
            state.playerHasPlayerZoneAccess = data.plyrNum ? true : false;
        },

        startLoadingPzPlayerAccesses(state) {
            state.loadingPzPlayerAccesses = true;
        },

        stopLoadingPzPlayerAccesses(state) {
            state.loadingPzPlayerAccesses = false;
        },

        setLoadingErrorPzPlayerAccesses(state, error) {
            state.loadingErrorPzPlayerAccesses = error;
        },

        clearLoadingErrorPzPlayerAccesses(state) {
            state.loadingErrorPzPlayerAccesses = null;
        },
    },

    actions: {
        async findPlayer(context, id) {
            context.commit('startLoadingPlayer');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/Player/${id}`);
                const resultData = result.data;

                //ToDo: this properties are here because are objects in the API and not in the model,
                //ToDo: also is not posible to map them in the model because the model can not be handle objects
                resultData.playerAnnualFormDate = resultData?.playerAnnualForm?.signatureDateForm || '';
                resultData.playerAnnualFormEmail = resultData?.playerAnnualForm?.signatureEmail || '';
                resultData.playerAnnualFormName = resultData?.playerAnnualForm?.signatureFullName || '';
                resultData.playerAnnualFormRelation = resultData?.playerAnnualForm?.signatureRelationship || '';

                resultData.playerHipaaFormDate = resultData?.playerHipaaForm?.signatureDateForm || '';
                resultData.playerHipaaFormEmail = resultData?.playerHipaaForm?.signatureEmail || '';
                resultData.playerHipaaFormName = resultData?.playerHipaaForm?.signatureFullName || '';
                resultData.playerHipaaFormRelation = resultData?.playerHipaaForm?.signatureRelationship || '';
                //end Todo

                context.commit('setPlayer', new Player(resultData));
                context.commit('clearLoadingError');
                context.commit('stopLoadingPlayer');
                return Promise.resolve(resultData);
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('stopLoadingPlayer');
                return Promise.reject(error);
            }
        },

        async createPlayer(context, player) {
            context.commit('startSavingPlayer');
            context.commit('clearLoadingError');
            if (player.data.email !== null) {
                var re =
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (!re.test(player.data.email)) {
                    context.commit('stopSavingPlayer');
                    return Promise.reject('Valid email required');
                }
            }
            try {
                const result = await axios.post(`${process.env.VUE_APP_API_URL}/Player`, player.data);
                context.commit('setPlayer', new Player(result.data));
                context.commit('stopSavingPlayer');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('stopSavingPlayer');
                return Promise.reject(error);
            }
        },

        async savePlayer(context) {
            context.commit('startSavingPlayer');
            context.commit('clearLoadingError');
            if (context.state.player.data.email !== null) {
                var re =
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (!re.test(context.state.player.data.email)) {
                    context.commit('stopSavingPlayer');
                    return Promise.reject({ message: 'Player email required' });
                }
            }
            if (context.state.player.data.coachEmail !== null) {
                var re =
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (!re.test(context.state.player.data.coachEmail)) {
                    context.commit('stopSavingPlayer');
                    return Promise.reject({ message: 'Coach email required' });
                }
            }

            if (context.state.player.data.agentEmail !== null) {
                var re =
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (!re.test(context.state.player.data.agentEmail)) {
                    context.commit('stopSavingPlayer');
                    return Promise.reject({ message: 'Agent email required' });
                }
            }

            if (context.state.player.data.registeredCoachEmail !== null) {
                var re =
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (!re.test(context.state.player.data.registeredCoachEmail)) {
                    context.commit('stopSavingPlayer');
                    return Promise.reject({ message: 'Registered Coach email required' });
                }
            }

            if (context.state.player.data.financialRepEmail !== null) {
                var re =
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (!re.test(context.state.player.data.financialRepEmail)) {
                    context.commit('stopSavingPlayer');
                    return Promise.reject({ message: 'Financial Rep email required' });
                }
            }

            if (context.state.player.data.paymentEmail !== null) {
                var re =
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (!re.test(context.state.player.data.paymentEmail)) {
                    context.commit('stopSavingPlayer');
                    return Promise.reject({ message: 'Payment email required' });
                }
            }
            try {
                const result = await axios.put(`${process.env.VUE_APP_API_URL}/Player`, context.state.player.data);
                context.commit('clearSavingError');
                context.commit('stopSavingPlayer');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setSavingError', error);
                context.commit('stopSavingPlayer');
                return Promise.reject(error);
            }
        },

        async deletePlayer(context, id) {
            context.commit('startSavingPlayer');
            context.commit('clearLoadingError');
            try {
                await axios.delete(`${process.env.VUE_APP_API_URL}/Player/${id}`);
                context.commit('clearSavingError');
                context.commit('stopSavingPlayer');
                return Promise.resolve();
            } catch (error) {
                context.commit('setSavingError', error);
                context.commit('stopSavingPlayer');
                return Promise.reject(error);
            }
        },

        async findPzAccessesForPlayers(context, playerId) {
            context.commit('startLoadingPzPlayerAccesses');
            context.commit('clearLoadingErrorPzPlayerAccesses');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/access/RetrievePzAccessForPlayers/${playerId}`);
                context.commit('setPlayerHasPlayerZoneAccess', result.data);
                context.commit('stopLoadingPzPlayerAccesses');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingErrorPzPlayerAccesses', error);
                context.commit('stopLoadingPzPlayerAccesses');
                return Promise.reject(error);
            }
        },

        async createPzAccessForPlayers(context, data) {
            context.commit('startLoadingPzPlayerAccesses');
            context.commit('clearLoadingErrorPzPlayerAccesses');
            try {
                const result = await axios.post(`${process.env.VUE_APP_API_URL}/access/CreatePzAccessForPlayers`, data.data);
                context.commit('stopLoadingPzPlayerAccesses');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('stopLoadingPzPlayerAccesses');
                return Promise.reject(error);
            }
        },
    },
};
