import { Model } from '@/models/model';

export class MembershipPaymentReports extends Model {}

MembershipPaymentReports.schema = {
    fields: {
        playerNbr: { type: 'string', displayName: 'Player ID', sortable: true, readonly: true },
        firstName: { type: 'string', displayName: 'First Name', sortable: true, readonly: true },
        lastName: { type: 'string', displayName: 'Last Name', sortable: true, readonly: true },
        memberType: {
            displayName: 'Current Year Membership',
            type: 'string',
            required: false,
            sysCode: 'MBR_TYPE',
            sortable: true,
            lookup: [
                { id: 'S', displayName: 'S' },
                { id: 'A', displayName: 'A' },
                { id: 'X', displayName: 'X' },
                { id: 'F', displayName: 'F' },
            ],
        },
        memberEligible: {
            displayName: 'Eligible Membership',
            type: 'string',
            required: false,
            sysCode: 'MBR_TYPE',
            sortable: true,
            lookup: [
                { id: 'S', displayName: 'S' },
                { id: 'A', displayName: 'A' },
                { id: 'X', displayName: 'X' },
                { id: 'F', displayName: 'F' },
            ],
        },
    },
};
