<template>
    <div v-loading="loadingPlayer || savingPlayer">
        <div v-if="player">
            <div class="flex-row mt-0 mb-32">
                <h2><i class="el-icon-user mr-16"></i>{{ player.original.fullName }} - {{ player.original.id }}</h2>
                <span class="spacer"></span>
                <el-button plain type="primary" v-if="playerHasPlayerZoneAccess === false" @click="addPlayerZoneAccessClick">Add Player Zone Access</el-button>
                <el-button plain type="danger" v-if="!isBaselineReader" @click="deletePlayerClick">Delete Player</el-button>
            </div>

            <ErrorDisplay :requestError="this.requestError" @closed="clearError" />

            <el-tabs v-model="activeTab">
                <el-tab-pane label="General" name="general">
                    <FormGroup :model="player" :schema="schema" :layout="layout" @change="onChange" />
                </el-tab-pane>

                <el-tab-pane label="Details" name="details">
                    <FormGroup :model="player" :schema="schema" :layout="layoutDetails" @change="onChange" />
                    <el-button @click="editCurrentRank" type="primary">Edit curent rank</el-button>
                    <el-button @click="editSpecialRank" type="primary">Edit special rank</el-button>
                </el-tab-pane>

                <el-tab-pane label="Dates" name="dates">
                    <FormGroup :model="player" :schema="schema" :layout="layoutDates" @change="onChange" />
                    <FormGroup :model="player" :schema="schema" :layout="layoutDatesTwo" @change="onChange" />
                </el-tab-pane>

                <el-tab-pane label="Membership Information" name="membership">
                    <FormGroup :model="player" :schema="schema" :layout="layoutMembership" @change="onChange" />
                </el-tab-pane>

                <el-tab-pane label="Contacts" name="persons">
                    <FormGroup :model="player" :schema="schema" :layout="layoutPersons" @change="onChange" />
                </el-tab-pane>

                <el-tab-pane label="Schedule Recipients" name="messageRecipients">
                    <MessagingRecipients :playerNbr="playerId" />
                </el-tab-pane>

                <el-tab-pane label="Payment Information" name="payments">
                    <FormGroup :model="player" :schema="schema" :layout="layoutPaymentGeneral" @change="onChange" />
                    <FormGroup :model="player" :schema="schema" :layout="layoutPayment1" @change="onChange" />
                    <FormGroup :model="player" :schema="schema" :layout="layoutPayment2" @change="onChange" />
                    <FormGroup :model="player" :schema="schema" :layout="layoutPayment3" @change="onChange" />
                    <FormGroup :model="player" :schema="schema" :layout="layoutPayment4" @change="onChange" />
                </el-tab-pane>

                <el-tab-pane label="Earnings" name="earnings">
                    <FormGroup :model="player" :schema="schema" :layout="layoutEarnings" @change="onChange" />
                </el-tab-pane>

                <el-tab-pane label="Bonuses" name="bonuses">
                    <PlayerBonuses :playerId="playerId" />
                </el-tab-pane>

                <el-tab-pane label="Biography" name="playerBioDet">
                    <PlayerBioDet :playerId="playerId" />
                </el-tab-pane>

                <el-tab-pane label="Player Development" name="playerDevelopment">
                    <PlayerDevelopment :playerId="playerId" />
                </el-tab-pane>

                <el-tab-pane label="Prize Money Summary" name="playerPayments">
                    <PlayersPlayerPayments :playerNbr="playerId" />
                </el-tab-pane>
            </el-tabs>

            <SaveChanges :model="player" @save="saveChanges" @discard="discardChanges" />
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { Player } from '../models/player';
import { deleteConfirmDto, retrieveDtos, createConfirmDto } from '../utils/crud-confirm';
import { PzAccess } from '../models/pz-access';
import { isBaselineReader } from '../services/authGroups';

export default {
    data() {
        return {
            playerId: this.$route.params.id,
            schema: Player.schema,
            layout: Player.layout,
            layoutDetails: Player.layoutDetails,
            layoutDates: Player.layoutDates,
            layoutDatesTwo: Player.layoutDatesTwo,
            layoutMembership: Player.layoutMembership,
            layoutPersons: Player.layoutPersons,
            layoutPaymentGeneral: Player.layoutPaymentGeneral,
            layoutPayment1: Player.layoutPayment1,
            layoutPayment2: Player.layoutPayment2,
            layoutPayment3: Player.layoutPayment3,
            layoutPayment4: Player.layoutPayment4,
            layoutEarnings: Player.layoutEarnings,
            requestError: null,
            activeTab: this.$route.query.activeTab || 'general',
            isBaselineReader: isBaselineReader(),
        };
    },
    mounted() {
        this.refresh();
        this.getPzAccess();
    },
    computed: {
        ...mapGetters('playerDetails', [
            'player',
            'loadingPlayer',
            'savingPlayer',
            'loadingError',
            'savingError',
            'playerHasPlayerZoneAccess',
            'loadingPzPlayerAccesses',
            'loadingErrorPzPlayerAccesses',
        ]),
    },
    methods: {
        ...mapActions('playerDetails', ['findPlayer', 'savePlayer', 'deletePlayer', 'findPzAccessesForPlayers', 'createPzAccessForPlayers']),
        refresh() {
            let params = {
                self: this,
                fRetrieve: this.findPlayer,
                options: this.playerId,
                dtosName: 'Player',
            };
            retrieveDtos(params);
        },
        editCurrentRank() {
            this.$router.push(`/ranking/current?playerId=${this.playerId}`);
        },
        editSpecialRank() {
            this.$router.push(`/ranking/special-ranking?playerId=${this.playerId}`);
        },
        saveChanges() {
            this.savePlayer()
                .then(() => {
                    this.player.saveChanges();
                    this.$notify({
                        title: 'Save changes',
                        message: 'Changes successfully saved!',
                        type: 'success',
                        duration: 5000,
                    });
                })
                .catch((error) => {
                    this.requestError = error;
                });
        },
        discardChanges() {
            this.player.discardChanges();
        },
        onChange(event) {
            // this is to prevent saving empty strings to the DB
            if (event.value === '') {
                this.player.data[event.field] = null;
            }
        },
        deletePlayerClick() {
            let params = {
                self: this,
                fDelete: this.deletePlayer,
                vConfirm: this.playerId,
                dto: this.playerId,
                dtoName: 'Player',
                vConfirmName: 'Player ID',
                callback: this.deleteCallback,
            };
            deleteConfirmDto(params);
        },
        deleteCallback() {
            this.$router.push('/players');
        },
        clearError() {
            this.requestError = null;
        },
        addPlayerZoneAccessClick() {
            let params = {
                self: this,
                fCreate: this.createPzAccessForPlayers,
                dto: new PzAccess({
                    plyrNum: this.playerId,
                    firstName: this.player.original.firstName,
                    lastName: this.player.original.lastName,
                    email: this.player.original.email,
                }),
                dtoName: 'Pz Access',
                callback: this.addPlayerZoneAccessCallback,
            };
            createConfirmDto(params);
        },
        addPlayerZoneAccessCallback() {
            this.$router.push('/players');
        },
        getPzAccess() {
            let params = {
                self: this,
                fRetrieve: this.findPzAccessesForPlayers,
                options: this.playerId,
                dtosName: 'Pz Access',
            };

            retrieveDtos(params);
        },
    },
};
</script>
