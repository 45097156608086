<template>
    <div>
        <div class="flex-row mb-32">
            <h2><i class="el-icon-medal-1 mr-16"></i>Ranking Summaries {{ displayRankingDate ? 'for ' + displayRankingDate : '' }}</h2>
            <span class="spacer"></span>
            <p>Ranking Date</p>
            <el-date-picker
                v-model="rankingDate"
                :firstDayOfWeek="1"
                type="week"
                format="yyyy-MM-dd"
                placeholder="Pick a day"
                class="ml-16 mr-32"
                @change="selectDate"
            ></el-date-picker>
            <p class="ml-32">Player</p>
            <SelectPlayer class="ml-16" v-model="playerId" @palyer-selected="selectPlayer" />
        </div>

        <ErrorDisplay :requestError="this.requestError" @closed="clearError" />

        <DetailedInformation screenName="Ranking Summaries" :informationArray="this.informationArray()" />

        <el-tabs v-if="rankingDate" v-model="activeTab">
            <el-tab-pane label="Most recent added" name="mostRecentAdded">
                <MostRecentAdded :rankingDate="rankingDate" />
            </el-tab-pane>

            <el-tab-pane label="Next to drop off" name="nextOff">
                <NextToDropOff :rankingDate="rankingDate" />
            </el-tab-pane>

            <el-tab-pane label="Next to go on" name="nextOn">
                <NextToGoOn :rankingDate="rankingDate" />
            </el-tab-pane>

            <el-tab-pane label="Singles Summary by Player" name="singles-summary">
                <RankingSummarySingles
                    :dataFields="[
                        'startDate',
                        'useForRankingDate',
                        'tournamentName',
                        'season',
                        'tournamentId',
                        'tournamentLevel',
                        'isRequired',
                        'points',
                        'isIncludedIntoRanking',
                        'rankingLabel',
                    ]"
                />
            </el-tab-pane>

            <el-tab-pane label="Doubles Summary by Player" name="doubles-summary">
                <RankingSummaryDoubles
                    :dataFields="[
                        'startDate',
                        'useForRankingDate',
                        'tournamentName',
                        'season',
                        'tournamentId',
                        'isRequired',
                        'points',
                        'isIncludedIntoRankingDoubles',
                        'rankingLabel',
                    ]"
                />
            </el-tab-pane>

            <el-tab-pane label="Champ Singles Summary by Player" name="race-singles-summary">
                <RankingSummaryChampSingles />
            </el-tab-pane>

            <el-tab-pane label="Champ Team Summaries by Teams" name="race-teams-summaries">
                <RankingSummaryChampTeams />
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { getMondayOfWeek } from '@/utils/date-utils';
import moment from 'moment';

export default {
    data() {
        return {
            rankingDate: null,
            displayRankingDate: null,
            playerId: null,
            activeTab: 'mostRecentAdded',
            requestError: null,
        };
    },
    mounted() {
        this.refresh();
    },
    computed: {
        ...mapGetters('wtaSystem', ['wtaSystem', 'loadingWtaSystem']),
    },
    methods: {
        ...mapActions('rankingProcedure', [
            'getNextOnTournaments',
            'getNextOffTournaments',
            'getMostRecentAddedTournaments',
            'dryRunRankingProcedure',
            'clearSummeries',
        ]),
        ...mapActions('wtaSystem', ['retrieveWtaSystem']),
        refresh() {
            this.requestError = null;
            this.retrieveWtaSystem()
                .then(() => {
                    if (!this.rankingDate) this.rankingDate = this.wtaSystem.data.currentRankDate;
                    let rankingDate = moment(this.rankingDate).format('YYYY-MM-DD');
                    this.displayRankingDate = rankingDate;
                    this.getNextOnTournaments(rankingDate);
                    this.getNextOffTournaments(rankingDate);
                    this.getMostRecentAddedTournaments(rankingDate);
                    if (!this.playerId) {
                        this.clearSummeries();
                        return;
                    }
                    let options = { rankingDate, playerId: this.playerId };
                    this.dryRunRankingProcedure(options);
                })
                .catch((error) => {
                    this.requestError = error;
                    this.$notify({
                        title: 'Get WTA System Error',
                        message: error.response.data.Message,
                        type: 'error',
                        duration: 7000,
                    });
                });
        },
        selectPlayer(player) {
            this.playerId = player ? player.id : null;
            this.refresh();
        },
        selectDate(date) {
            this.rankingDate = date ? getMondayOfWeek(date) : null;
            this.refresh();
        },
        clearError() {
            this.requestError = null;
        },
        informationArray() {
            return [
                'This screen is used for insights into Ranking details.',
                '"Most Recent Added" and "Next To Dropp Off" contain a list of all tournaments which are added at selected ranking date or will drop on the next run.',
                'Ranking Summaries contain all Tournament Results for each player and indicates if and how these are used for ranking.',
                '<b>Combined Total Points</b> and <b>Total International Points</b> in Championship Singles Summary are computed on tournament results from past 52 weeks.',
                'Searching is possible by player id <b>or</b> last name <b>or</b> first name. Combined search is not possible.',
                'Date selection always picks Monday of selected week as Ranking Date.',
                'Selecting past and future Rankings is possible and will not result in any change of database.',
            ];
        },
    },
};
</script>

<style scoped></style>
