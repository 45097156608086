<template>
    <div>
        <div class="flex-row mt-16 mb-32">
            <h2>Membership Payment Report</h2>
            <span class="spacer"></span>
            <el-input
                placeholder="Filter by Id or Name"
                v-model="options.queryString"
                class="input-search mr-16"
                @change="resetPage"
                @keydown.esc.native="reset"
            >
                <el-button slot="append" icon="el-icon-search" @click="resetPage"></el-button>
            </el-input>
        </div>
        <ErrorDisplay :requestError="this.loadingError" @closed="clearError" />
        <div v-loading="loadingMembershipPaymentData">
            <el-table :data="membershipPaymentData.data" style="width: 100%">
                <el-table-column prop="playerNbr" label="Player ID" width="180" sortable></el-table-column>
                <el-table-column prop="firstName" label="First Name" width="200" sortable></el-table-column>
                <el-table-column prop="lastName" label="Last Name" width="200" sortable></el-table-column>
                <el-table-column label="Current Year Membership" width="280">
                    <template #default="scope">
                        <el-select v-model="scope.row.memberType" @change="updateValue(scope.row)" placeholder="Select">
                            <el-option v-for="option in ddlMemberTypeOptions" :key="option.value" :label="option.label" :value="option.value" />
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column label="Eligible Membership" width="280">
                    <template #default="scope">
                        <el-select v-model="scope.row.memberEligible" @change="updateValue(scope.row)" placeholder="Select">
                            <el-option v-for="option in ddlEligibleTypeOptions" :key="option.value" :label="option.label" :value="option.value" />
                        </el-select>
                    </template>
                </el-table-column>
            </el-table>
            <Paginator
                v-if="membershipPaymentData.data"
                :data="membershipPaymentData"
                :elementsPerPage="options.pageSize"
                @prev-page="prevPage"
                @next-page="nextPage"
                @set-page-size="setPageSize"
            />
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { retrieveDtos, updateConfirmDto } from '@/utils/crud-confirm';
import { MembershipPaymentReports } from '@/models/membership-payment-reports';

export default {
    data() {
        return {
            schema: MembershipPaymentReports.schema,
            nationality: null,
            activeTab: this.$route.query.activeTab || 'membershipPaymentData',
            ddlEligibleTypeOptions: [
                { value: 'A', label: 'ASSOCIATE' },
                { value: 'F', label: 'FULL' },
                { value: 'S', label: 'SUBSCRIBER' },
                { value: ' ', label: 'Nothing' },
            ],
            ddlMemberTypeOptions: [
                { value: 'A', label: 'ASSOCIATE' },
                { value: 'F', label: 'FULL' },
                { value: 'S', label: 'SUBSCRIBER' },
            ],
        };
    },
    mounted() {
        this.search();
    },
    computed: mapGetters('membershipPaymentReports', ['membershipPaymentData', 'loadingMembershipPaymentData', 'options', 'loadingError']),
    methods: {
        ...mapActions('membershipPaymentReports', ['searchMembershipPaymentData', 'updateMembershipPaymentData']),
        search() {
            let params = {
                self: this,
                fRetrieve: this.searchMembershipPaymentData,
                options: this.options,
            };

            retrieveDtos(params);
        },
        updateValue(row) {
            this.selectedMembershipOptions = new MembershipPaymentReports({
                playerNbr: row.playerNbr,
                memberType: row.memberType,
                memberEligible: row.memberEligible,
            });
            let params = {
                self: this,
                fUpdate: this.updateMembershipPaymentData,
                dto: this.selectedMembershipOptions,
                dtoName: 'UpdateMembership',
                callback: this.saveCallback,
            };
            updateConfirmDto(params);
            this.search();
        },
        resetSearch() {
            this.options.queryString = null;
            this.search();
        },
        setPageSize(size) {
            this.options.pageSize = size;
            this.search();
        },
        resetPage() {
            this.options.page = 0;
            this.search();
        },
        reset() {
            this.options.queryString = null;
            this.options.owesMoney = false;
            this.options.page = 0;
        },
        setSort(event) {
            this.options.sortBy = event.property;
            this.options.sortDir = event.order;
            this.search();
        },
        prevPage() {
            this.options.page--;
            this.search();
        },
        nextPage() {
            this.options.page++;
            this.search();
        },
        clearError() {
            this.loadingError = null;
        },
        saveCallback() {
            this.search();
            this.requestError = null;
        },
    },
};
</script>

<style scoped></style>
