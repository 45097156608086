var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"flex-row mb-32"},[_c('h2',[_c('i',{staticClass:"el-icon-medal-1 mr-16"}),_vm._v("Ranking Summaries "+_vm._s(_vm.displayRankingDate ? 'for ' + _vm.displayRankingDate : ''))]),_c('span',{staticClass:"spacer"}),_c('p',[_vm._v("Ranking Date")]),_c('el-date-picker',{staticClass:"ml-16 mr-32",attrs:{"firstDayOfWeek":1,"type":"week","format":"yyyy-MM-dd","placeholder":"Pick a day"},on:{"change":_vm.selectDate},model:{value:(_vm.rankingDate),callback:function ($$v) {_vm.rankingDate=$$v},expression:"rankingDate"}}),_c('p',{staticClass:"ml-32"},[_vm._v("Player")]),_c('SelectPlayer',{staticClass:"ml-16",on:{"palyer-selected":_vm.selectPlayer},model:{value:(_vm.playerId),callback:function ($$v) {_vm.playerId=$$v},expression:"playerId"}})],1),_c('ErrorDisplay',{attrs:{"requestError":this.requestError},on:{"closed":_vm.clearError}}),_c('DetailedInformation',{attrs:{"screenName":"Ranking Summaries","informationArray":this.informationArray()}}),(_vm.rankingDate)?_c('el-tabs',{model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('el-tab-pane',{attrs:{"label":"Most recent added","name":"mostRecentAdded"}},[_c('MostRecentAdded',{attrs:{"rankingDate":_vm.rankingDate}})],1),_c('el-tab-pane',{attrs:{"label":"Next to drop off","name":"nextOff"}},[_c('NextToDropOff',{attrs:{"rankingDate":_vm.rankingDate}})],1),_c('el-tab-pane',{attrs:{"label":"Next to go on","name":"nextOn"}},[_c('NextToGoOn',{attrs:{"rankingDate":_vm.rankingDate}})],1),_c('el-tab-pane',{attrs:{"label":"Singles Summary by Player","name":"singles-summary"}},[_c('RankingSummarySingles',{attrs:{"dataFields":[
                    'startDate',
                    'useForRankingDate',
                    'tournamentName',
                    'season',
                    'tournamentId',
                    'tournamentLevel',
                    'isRequired',
                    'points',
                    'isIncludedIntoRanking',
                    'rankingLabel',
                ]}})],1),_c('el-tab-pane',{attrs:{"label":"Doubles Summary by Player","name":"doubles-summary"}},[_c('RankingSummaryDoubles',{attrs:{"dataFields":[
                    'startDate',
                    'useForRankingDate',
                    'tournamentName',
                    'season',
                    'tournamentId',
                    'isRequired',
                    'points',
                    'isIncludedIntoRankingDoubles',
                    'rankingLabel',
                ]}})],1),_c('el-tab-pane',{attrs:{"label":"Champ Singles Summary by Player","name":"race-singles-summary"}},[_c('RankingSummaryChampSingles')],1),_c('el-tab-pane',{attrs:{"label":"Champ Team Summaries by Teams","name":"race-teams-summaries"}},[_c('RankingSummaryChampTeams')],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }