<template>
    <div class="entryList" style="width: 100%;">
        <table class="DoublesEntries entry" style="width: 80%; margin-left: auto; margin-right:auto;">
            <thead class="full-width">
                <tr>
                    <th style="width:5%">Pos</th>
                    <th style="width:5%">Combined Rank</th>
                    <th style="width:5%"></th>
                    <th style="width:25%">Player Name</th>
                    <th style="width:5%">Nat.</th>      
                    <th style="width:5%" v-if="showSinglesRankColumn">Singles Rank</th>              
                    <th style="width:5%">Doubles Rank</th>
                    <th style="width:5%"></th>
                    <th style="width:20%">Partner Name</th>
                    <th style="width:5%">Nat.</th>    
                    <th style="width:5%"  v-if="showSinglesRankColumn">Singles Rank</th>                
                    <th style="width:5%">Doubles Rank</th>
                </tr>
            </thead>
            <tbody>                
                <tr v-for="(entry, index) in draw" v-bind:key="entry" :style="computeStyleForEntry(entry)">
                    <td>{{ index + 1 }}</td>
                    <td>{{ entry.combinedRank }}</td>
                    <td>
                        <b>{{ entry.playerMembership }}</b>
                    </td>
                    <td>{{ entry.playerName }}</td>
                    <td>{{ entry.playerNationality }}</td> 
                    <td v-if="showSinglesRankColumn">{{ entry.playerSinglesRank }}</td>                   
                    <td>{{ entry.playerRank }}</td>
                    <td>
                        <b>{{ entry.partnerMembership }}</b>
                    </td>
                    <td>{{ entry.partnerName }}</td>
                    <td>{{ entry.partnerNationality }}</td>   
                    <td  v-if="showSinglesRankColumn">{{ entry.partnerSinglesRank }}</td>                 
                    <td>{{ entry.partnerRank }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
export default {
    name: 'DoublesEntryList',
    props: {
        draw: {},
        edition: null,
        showSinglesRankColumn: false,
    },
    methods: {       
        computeStyleForEntry: function(entry) {             
            if(this.edition.type == 'GrandSlam') {
                this.showSinglesRankColumn = true;
            }        
            if (entry.status === 'AcceptedIntoMain' || entry.status === 'Entered') {
                return '';
            } else if (entry.status === 'WithdrawnFromMainDrawAfterEntryDeadline' || entry.status === 'WithdrawnAfterEntryDeadline') {
                let cssStrikethrough = 'text-decoration: line-through;';
                if (this.edition.tournamentAcceptanceListDoublesMainIsPublished === true) {
                    return cssStrikethrough;
                }
                return 'display: none;';
            } else {
                return 'display: none;';
            }
        },
    },
};
</script>
<style>
.AL h3 {
    margin-top: 25px;
}

.list h3 {
    text-align: center;
    line-height: 1.28571429em;
    font-weight: 900;
    font-size: 24px;
}

.SinglesList tr td:nth-child(1),
.DoublesList tr td:nth-child(1) {
    font-weight: 900;
}

#allLists h3 {
    margin-top: 50px !important;
}
</style>
