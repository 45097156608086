<template>
    <div>
        <el-autocomplete
            :value="value"
            :placeholder="placeholder || 'Select Nationality'"
            :disabled="disabled"
            :minlength="minlength"
            :maxlength="maxlength"
            :clearable="clearable"
            :fetch-suggestions="nationalityQuerySearch"
            @input="onChange"
            @clear="onClear"
            @select="onSelect"
        >
            <template slot="prepend">
                <div v-if="value === 'TPE'" style="margin: 0 -0.9em -0.6em -0.7em; width: 42px; height: 39px; display: inline-block">
                    <img
                        src="@/assets/TPE-Olympic.png"
                        alt="brand logo"
                        style="margin: 0 -0.9em -0.6em 0.6em; width: 20px; height: 12px; display: inline-block"
                    />
                </div>
                <div v-else>
                    <country-flag v-if="value && lookup.includes(value)" :country="toIso3166(value)" size="small" />
                    <i v-else class="el-icon-warning-outline"></i>
                </div>
            </template>
        </el-autocomplete>
    </div>
</template>

<script>
import { convertIocCode } from '../utils/country-codes';
export default {
    props: ['value', 'disabled', 'clearable', 'minlength', 'maxlength', 'lookup', 'placeholder'],
    data() {
        return {
            nationalityAutocompletes: [],
            loading: false,
        };
    },
    mounted() {
        if (this.lookup && this.lookup.length) {
            this.loadAllNationalities(this.lookup);
        }
    },
    methods: {
        nationalityQuerySearch(queryString, cb) {
            var nats = this.nationalityAutocompletes;
            var results = queryString ? nats.filter(this.createNationalityFilter(queryString)) : nats;
            // call callback function to return suggestions
            cb(results);
        },
        createNationalityFilter(queryString) {
            return (nat) => {
                return nat.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
            };
        },
        loadAllNationalities(lookup) {
            this.nationalityAutocompletes = [];
            lookup.sort();
            lookup.forEach((item) => {
                this.nationalityAutocompletes.push({
                    value: item,
                });
            });
        },
        onChange(item) {
            this.$emit('input', item);
            this.$emit('change', item);
        },
        onClear() {
            this.$emit('clear');
        },
        onSelect(item) {
            this.$emit('select', item);
        },
        toIso3166(code) {
            return code ? convertIocCode(code).iso3 : '';
        },
    },
};
</script>

<style scoped>
.el-autocomplete {
    width: 100%;
}
</style>
