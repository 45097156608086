<template>
    <div v-loading="loadingTournament">
        <div v-if="tournament">
            <div class="flex-row mt-0 mb-32">
                <h2>
                    <i class="el-icon-trophy-1 mr-16"></i>{{ tournament.original.season }} {{ tournament.original.name }} - {{ tournament.data.tournamentId }}
                </h2>
                <span class="spacer"></span>
                <el-button plain type="danger" v-if="!isBaselineReader" @click="deleteTournamentClick">Delete tournament</el-button>
            </div>

            <ErrorDisplay :requestError="this.requestError" @closed="clearError" />

            <el-alert
                type="warning"
                effect="dark"
                :closable="false"
                :show-icon="false"
                class="mt-0 mb-16"
                v-if="hasChangesRelevantForAcceptanceListSinglesMain() || hasChangesRelevantForAcceptanceListSinglesQualification()"
            >
                <div>
                    Current Direct In for Singles Main:
                    <b
                        >draw {{ tournament.data.singlesMainDrawSize }}, bye {{ tournament.data.singlesMainByeCount }}, except
                        {{ tournament.data.singlesMainExceptionCount }}, wildcard {{ tournament.data.singlesMainWildCardCount }}, quali
                        {{ tournament.data.singlesMainQualifiedCount }}</b
                    >
                    <span v-if="hasChangesRelevantForAcceptanceListSinglesMain()">
                        &raquo;&nbsp;New Value after storing changes:
                        <b>{{
                            parseInt(tournament.data.singlesMainDrawSize) -
                            parseInt(tournament.data.singlesMainByeCount) -
                            parseInt(tournament.data.singlesMainExceptionCount) -
                            parseInt(tournament.data.singlesMainWildCardCount) -
                            parseInt(tournament.data.singlesMainQualifiedCount)
                        }}</b
                        ><br />
                    </span>
                </div>
                <div>
                    Current Direct In for Singles Qualification:
                    <b
                        >draw {{ tournament.data.singlesMainDrawSize }}, bye {{ tournament.data.singlesMainByeCount }}, except
                        {{ tournament.data.singlesQualificationExceptionCount }}, wildcard {{ tournament.data.singlesMainWildCardCount }}</b
                    >
                    <span v-if="hasChangesRelevantForAcceptanceListSinglesQualification()">
                        &raquo;&nbsp;New Value after storing changes:
                        <b>{{
                            parseInt(tournament.data.singlesQualificationDrawSize) -
                            parseInt(tournament.data.singlesQualificationByeCount) -
                            parseInt(tournament.data.singlesQualificationExceptionCount) -
                            parseInt(tournament.data.singlesQualificationWildCardCount)
                        }}</b>
                    </span>
                </div>
            </el-alert>

            <el-alert
                type="error"
                :show-icon="true"
                v-if="hasChangesRelevantForAcceptanceListSinglesMain() || hasChangesRelevantForAcceptanceListSinglesQualification()"
            >
                You are about to change the size of direct ins. This will lead to the acceptance list being changed if applicable. Shortening the size is
                currently not supported as a withdrawal is not supported being performed automatically. If you extend the size of direct ins then the respective
                number of alternates will be promoted to main draw. This all holds only if the deadline for the draw has already passed and the acceptance list
                has been created.
            </el-alert>

            <el-tabs value="general">
                <el-tab-pane label="General" name="general">
                    <FormGroup :model="tournament" :schema="schema" :layout="layout" @change="onChange" />
                </el-tab-pane>

                <el-tab-pane :label="entriesLabel()" name="preliminary_list">
                    <PreliminaryList />
                </el-tab-pane>

                <el-tab-pane :label="tournamentResultsLabel()" name="results">
                    <TournamentResults :tournamentId="id" :tournamentYear="year" ref="tournamentResults" />
                </el-tab-pane>

                <el-tab-pane :label="roundResultsLabel()" name="tourn_rounds">
                    <TournamentRounds
                        :tournamentId="id"
                        :tournamentYear="year"
                        :startDate="tournament.data.startDate"
                        :pointsRankDate="tournament.data.pointsRankDate"
                        @deletedAllTournRounds="refreshResults"
                        @createdTouramentResults="refreshResults"
                    />
                </el-tab-pane>

                <el-tab-pane :label="entriesAdminLabel()" name="raw_entries">
                    <TournamentRawEntries :tournamentId="id" :tournamentYear="year" />
                </el-tab-pane>

                <el-tab-pane :label="playerPaymentsLabel()" name="player_payments">
                    <PlayerPayments :tournYear="year" :tournNbr="id" />
                </el-tab-pane>

                <el-tab-pane :label="mixedDoublesLabel()" name="mixed_doubles">
                    <MixedDoubles :tournamentId="id" :tournamentYear="year" />
                </el-tab-pane>
            </el-tabs>

            <SaveChanges
                :model="tournament"
                @save="saveChanges"
                @discard="discardChanges"
                :show-additional-message="hasChangesRelevantForAcceptanceListSinglesQualification() || hasChangesRelevantForAcceptanceListSinglesMain()"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { Tournament } from '../models/tournament';
import { isBaselineReader } from '../services/authGroups';

export default {
    data() {
        return {
            id: this.$route.params.id,
            year: this.$route.params.year,
            schema: Tournament.schema,
            layout: Tournament.layout,
            requestError: null,
            isBaselineReader: isBaselineReader(),
        };
    },
    mounted() {
        this.findTournament({
            year: this.year,
            tournamentId: this.id,
        });
    },
    computed: {
        ...mapGetters('tournamentDetails', ['tournament', 'loadingTournament']),
        ...mapGetters('tournamentRounds', ['tournamentRounds']),
        ...mapGetters('tournamentResults', ['tournamentResults']),
        ...mapGetters('singlesEntries', ['singlesEntries']),
        ...mapGetters('doublesEntries', ['doublesEntries']),
        ...mapGetters('tournamentsPlayerPayments', ['playerPayments']),
        ...mapGetters('mixedDoubles', ['mixedDoubles']),
    },
    methods: {
        ...mapActions('tournamentDetails', ['findTournament', 'saveTournament', 'deleteTournament']),
        saveChanges() {
            this.saveTournament().then(() => {
                if (this.hasChangesRelevantForAcceptanceListSinglesMain() || this.hasChangesRelevantForAcceptanceListSinglesQualification()) {
                    this.$notify({
                        title: 'Save changes',
                        message:
                            'Changes successfully saved. As you performed changes that will change the draw size the acceptance list has been reprocessed if applicable. Please reload this page to see all updated data.',
                        type: 'warning',
                        duration: 5000,
                    });
                } else {
                    this.$notify({
                        title: 'Save changes',
                        message: 'Changes successfully saved!',
                        type: 'success',
                        duration: 5000,
                    });
                }
                if (this.tournament.isFieldChanged('currency')) {
                    this.refreshResults();
                }
                this.tournament.saveChanges();
            }).catch((error) => {
                this.$notify({
                    title: 'Error Saving the data',
                    message: error.response.data.name,
                    type: 'error',
                    duration: 7000,
                });
            });
        },
        hasChangesRelevantForAcceptanceListSinglesMain() {
            return (
                this.tournament.isFieldChanged('singlesMainDrawSize') ||
                this.tournament.isFieldChanged('singlesMainWildCardCount') ||
                this.tournament.isFieldChanged('singlesMainByeCount') ||
                this.tournament.isFieldChanged('singlesMainExceptionCount')
            );
        },
        hasChangesRelevantForAcceptanceListSinglesQualification() {
            return (
                this.tournament.isFieldChanged('singlesQualificationDrawSize') ||
                this.tournament.isFieldChanged('singlesQualificationWildCardCount') ||
                this.tournament.isFieldChanged('singlesQualificationByeCount') ||
                this.tournament.isFieldChanged('singlesQualificationExceptionCount')
            );
        },
        discardChanges() {
            this.tournament.discardChanges();
        },
        onChange(event) {
            if (event.value === '') {
                this.tournament.data[event.field] = null;
            }
        },
        refreshResults() {
            this.$refs.tournamentResults.refresh();
        },
        deleteTournamentClick() {
            this.$confirm('You are about to delete a tournament. Please confirm.', 'Delete tournament', {
                confirmButtonText: 'Delete tournament',
                cancelButtonText: 'Cancel',
                type: 'warning',
            })
                .then(() => {
                    this.$prompt(
                        'Warning! This will irrevocably delete the selected tournament. To continue, please enter the Tournament ID.',
                        'Delete tournament',
                        {
                            confirmButtonText: 'Delete tournament',
                            cancelButtonText: 'Cancel',
                            type: 'warning',
                        },
                    )
                        .then((result) => {
                            if (result.action === 'confirm' && result.value === this.id) {
                                this.deleteTournament({
                                    year: this.year,
                                    tournamentId: this.id,
                                })
                                    .then(() => {
                                        this.$notify({
                                            title: 'Delete tournament',
                                            message: 'Tournament deleted',
                                            type: 'success',
                                            duration: 5000,
                                        });
                                        this.$router.push('/tournaments');
                                    })
                                    .catch((er) => {
                                        this.$notify({
                                            title: 'Delete tournament',
                                            message: 'Tournament could not be deleted',
                                            type: 'error',
                                            duration: 5000,
                                        });
                                    });
                            } else {
                                this.$notify({
                                    title: 'Delete tournament',
                                    message: 'Tournament not deleted',
                                    type: 'info',
                                    duration: 5000,
                                });
                            }
                        })
                        .catch(() => {});
                })
                .catch(() => {});
        },
        entriesLabel() {
            return 'Entries (' + this.singlesEntries.length + '+' + this.doublesEntries.length + ')';
        },
        tournamentResultsLabel() {
            return 'Tournament Results (' + this.tournamentResults.length + ')';
        },
        roundResultsLabel() {
            return 'Round Results (' + this.tournamentRounds.length + ')';
        },
        entriesAdminLabel() {
            return 'Entries (Admin) (' + this.singlesEntries.length + '+' + this.doublesEntries.length + ')';
        },
        playerPaymentsLabel() {
            return 'Player Payments (' + this.playerPayments.length + ')';
        },
        mixedDoublesLabel() {
            return 'Mixed Doubles (' + this.mixedDoubles.length + ')';
        },
        clearError() {
            this.requestError = null;
        },
    },
};
</script>
